import * as yup from 'yup';
const numberValidator = () => yup
    .number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable();
export const advancedSettingGen3Config = (t) => {
    return [
        {
            groupTitle: 'Tau Coefficient',
            children: [
                {
                    title: 'Tau',
                    code: 'inverter_tau_coefficient',
                    type: 'number',
                    min: '0.0',
                    max: '6.0',
                    schema: () => numberValidator().min(0).max(6.0).unitNumber(0.1),
                },
            ],
        },
        {
            groupTitle: 'Inverter Controls Basic Settings',
            children: [
                {
                    title: 'Vref',
                    code: 'inverter_control_grid_target_voltage',
                    type: 'number',
                    min: '0.0',
                    max: '300.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(300).unitNumber(0.1),
                },
                {
                    title: 'Vref Offset',
                    code: 'inverter_control_grid_target_voltage_offset',
                    type: 'number',
                    min: '-300.0',
                    max: '300.0',
                    unit: 'V',
                    schema: () => numberValidator().min(-300).max(300).unitNumber(0.1),
                },
                {
                    title: 'PCS Max Apparent Power',
                    code: 'inverter_control_pcs_max_apparent_power_limit',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'VA',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'VArAct',
                    code: 'inverter_control_v_ar_act',
                    type: 'radio',
                    options: [
                        { text: 'switch', value: 1 },
                        { text: 'maintain', value: 2 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'VArMaxQ1',
                    code: 'inverter_control_pcs_var_max_q1',
                    type: 'number',
                    min: '-10000',
                    max: '10000',
                    unit: 'VAr',
                    schema: () => numberValidator().integer().min(-10000).max(10000),
                },
                {
                    title: 'VArMaxQ2',
                    code: 'inverter_control_pcs_var_max_q2',
                    type: 'number',
                    min: '-10000',
                    max: '10000',
                    unit: 'VAr',
                    schema: () => numberValidator().integer().min(-10000).max(10000),
                },
                {
                    title: 'VArMaxQ3',
                    code: 'inverter_control_pcs_var_max_q3',
                    type: 'number',
                    min: '-10000',
                    max: '10000',
                    unit: 'VAr',
                    schema: () => numberValidator().integer().min(-10000).max(10000),
                },
                {
                    title: 'VArMaxQ4',
                    code: 'inverter_control_pcs_var_max_q4',
                    type: 'number',
                    min: '-10000',
                    max: '10000',
                    unit: 'VAr',
                    schema: () => numberValidator().integer().min(-10000).max(10000),
                },
                {
                    title: 'PFMinQ1',
                    code: 'inverter_control_pf_min_q1',
                    type: 'number',
                    min: '-1.00',
                    max: '1.00',
                    schema: () => numberValidator().min(-1).max(1).unitNumber(0.01),
                },
                {
                    title: 'PFMinQ2',
                    code: 'inverter_control_pf_min_q2',
                    type: 'number',
                    min: '-1.00',
                    max: '1.00',
                    schema: () => numberValidator().min(-1).max(1).unitNumber(0.01),
                },
                {
                    title: 'PFMinQ3',
                    code: 'inverter_control_pf_min_q3',
                    type: 'number',
                    min: '-1.00',
                    max: '1.00',
                    schema: () => numberValidator().min(-1).max(1).unitNumber(0.01),
                },
                {
                    title: 'PFMinQ4',
                    code: 'inverter_control_pf_min_q4',
                    type: 'number',
                    min: '-1.00',
                    max: '1.00',
                    schema: () => numberValidator().min(-1).max(1).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: 'Over Voltage',
            children: [
                {
                    title: 'HVRT',
                    code: 'frt_hvrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'HVRT Start Level',
                    code: 'frt_start_level_hvrt',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1, 100),
                },
                {
                    title: 'Detection Level1',
                    code: 'grid_voltage_over_voltage_detection_level1',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1, 100),
                },
                {
                    title: 'Detection Time1',
                    code: 'grid_voltage_over_voltage_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Detection Level2',
                    code: 'grid_voltage_over_voltage_detection_level2',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1, 100),
                },
                {
                    title: 'Detection Time2',
                    code: 'grid_voltage_over_voltage_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Detection Level3',
                    code: 'grid_voltage_over_voltage_detection_level3',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1, 100),
                },
                {
                    title: 'Detection Time3',
                    code: 'grid_voltage_over_voltage_detection_time3',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Detection Level4',
                    code: 'grid_voltage_over_voltage_detection_level4',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1, 100),
                },
                {
                    title: 'Detection Time4',
                    code: 'grid_voltage_over_voltage_detection_time4',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Release Level',
                    code: 'grid_voltage_over_voltage_release_level',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1, 100),
                },
                {
                    title: 'Release Time',
                    code: 'grid_voltage_over_voltage_release_time',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
            ],
        },
        {
            groupTitle: '10Min Voltage',
            children: [
                {
                    title: 'Detection Level',
                    code: 'grid_voltage_ten_min_voltage_detection_level',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1, 100),
                },
            ],
        },
        {
            groupTitle: 'Under Voltage',
            children: [
                {
                    title: 'LVRT',
                    code: 'frt_lvrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'LVRT Start Level',
                    code: 'frt_start_level_lvrt',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1, 0),
                },
                {
                    title: 'Detection Level1',
                    code: 'grid_voltage_under_voltage_detection_level1',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1, 0),
                },
                {
                    title: 'Detection Time1',
                    code: 'grid_voltage_under_voltage_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Detection Level2',
                    code: 'grid_voltage_under_voltage_detection_level2',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1, 0),
                },
                {
                    title: 'Detection Time2',
                    code: 'grid_voltage_under_voltage_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Detection Level3',
                    code: 'grid_voltage_under_voltage_detection_level3',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1, 0),
                },
                {
                    title: 'Detection Time3',
                    code: 'grid_voltage_under_voltage_detection_time3',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Detection Level4',
                    code: 'grid_voltage_under_voltage_detection_level4',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1, 0),
                },
                {
                    title: 'Detection Time4',
                    code: 'grid_voltage_under_voltage_detection_time4',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
                {
                    title: 'Release Level',
                    code: 'grid_voltage_under_voltage_release_level',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1, 0),
                },
                {
                    title: 'Release Time',
                    code: 'grid_voltage_under_voltage_release_time',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
            ],
        },
        {
            groupTitle: 'Over Frequency',
            children: [
                {
                    title: 'HFRT',
                    code: 'frt_hfrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'HFRT Start Level',
                    code: 'frt_start_level_hfrt',
                    type: 'number',
                    min: '50.000',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(65).unitNumber(0.001, 50),
                },
                {
                    title: 'Detection Level1',
                    code: 'grid_frequency_over_frequency_detection_level1',
                    type: 'number',
                    min: '50.000',
                    max: '70.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(70).unitNumber(0.001, 50),
                },
                {
                    title: 'Detection Time1',
                    code: 'grid_frequency_over_frequency_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Detection Level2',
                    code: 'grid_frequency_over_frequency_detection_level2',
                    type: 'number',
                    min: '50.000',
                    max: '70.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(70).unitNumber(0.001, 50),
                },
                {
                    title: 'Detection Time2',
                    code: 'grid_frequency_over_frequency_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Detection Level3',
                    code: 'grid_frequency_over_frequency_detection_level3',
                    type: 'number',
                    min: '50.000',
                    max: '70.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(70).unitNumber(0.001, 50),
                },
                {
                    title: 'Detection Time3',
                    code: 'grid_frequency_over_frequency_detection_time3',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Detection Level4',
                    code: 'grid_frequency_over_frequency_detection_level4',
                    type: 'number',
                    min: '50.000',
                    max: '70.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(70).unitNumber(0.001, 50),
                },
                {
                    title: 'Detection Time4',
                    code: 'grid_frequency_over_frequency_detection_time4',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Detection Level5',
                    code: 'grid_frequency_over_frequency_detection_level5',
                    type: 'number',
                    min: '50.000',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(65).unitNumber(0.001, 50),
                },
                {
                    title: 'Detection Time5',
                    code: 'grid_frequency_over_frequency_detection_time5',
                    type: 'number',
                    min: '0',
                    max: '1000000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(1000000),
                },
                {
                    title: 'Release Level',
                    code: 'grid_frequency_over_frequency_release_level',
                    type: 'number',
                    min: '50.000',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(65).unitNumber(0.001, 50),
                },
                {
                    title: 'Release Time',
                    code: 'grid_frequency_over_frequency_release_time',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
            ],
        },
        {
            groupTitle: 'Under Frequency',
            children: [
                {
                    title: 'LFRT',
                    code: 'frt_lfrt',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'LFRT Start Level',
                    code: 'frt_start_level_lfrt',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
                },
                {
                    title: 'Detection Level1',
                    code: 'grid_frequency_under_frequency_detection_level1',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
                },
                {
                    title: 'Detection Time1',
                    code: 'grid_frequency_under_frequency_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '100000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(100000),
                },
                {
                    title: 'Detection Level2',
                    code: 'grid_frequency_under_frequency_detection_level2',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
                },
                {
                    title: 'Detection Time2',
                    code: 'grid_frequency_under_frequency_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '100000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(100000),
                },
                {
                    title: 'Detection Level3',
                    code: 'grid_frequency_under_frequency_detection_level3',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
                },
                {
                    title: 'Detection Time3',
                    code: 'grid_frequency_under_frequency_detection_time3',
                    type: 'number',
                    min: '0',
                    max: '100000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(100000),
                },
                {
                    title: 'Detection Level4',
                    code: 'grid_frequency_under_frequency_detection_level4',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
                },
                {
                    title: 'Detection Time4',
                    code: 'grid_frequency_under_frequency_detection_time4',
                    type: 'number',
                    min: '0',
                    max: '100000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(100000),
                },
                {
                    title: 'Detection Level5',
                    code: 'grid_frequency_under_frequency_detection_level5',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
                },
                {
                    title: 'Detection Time5',
                    code: 'grid_frequency_under_frequency_detection_time5',
                    type: 'number',
                    min: '0',
                    max: '100000',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(100000),
                },
                {
                    title: 'Release Level',
                    code: 'grid_frequency_under_frequency_release_level',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001, 45),
                },
                {
                    title: 'Release Time',
                    code: 'grid_frequency_under_frequency_release_time',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'msec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
            ],
        },
        {
            groupTitle: 'Active Power SetPoint',
            children: [
                {
                    title: 'SetPoint',
                    code: 'active_power_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'SetPoint Value:',
                    code: 'active_power_set_point_value',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Active Power SetPoint Gradient',
                    code: 'gradient_active_power_set_point_gradient',
                    type: 'number',
                    min: '0.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: 'Active Power Frequency P(freq)',
            children: [
                {
                    title: 'Frequency',
                    code: 'active_power_frequency_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'OF Pref Select',
                    code: 'active_power_frequency_of_pref_select',
                    type: 'radio',
                    options: [
                        { text: 'Pmax', value: 0 },
                        { text: 'Pref', value: 1 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'UF Pref Select',
                    code: 'active_power_frequency_uf_pref_select',
                    type: 'radio',
                    options: [
                        { text: 'Pmax', value: 0 },
                        { text: 'Pref', value: 1 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Slope Select',
                    code: 'active_power_frequency_slope_select',
                    type: 'radio',
                    options: [
                        { text: 'Curve', value: 0 },
                        { text: 'Slope', value: 1 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Hysteresis Select',
                    code: 'active_power_frequency_hysteresis_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Active Power Priority',
                    code: 'active_power_frequency_priority_select_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'SL(Kuf): Delta P',
                    code: 'active_power_frequency_sl',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(-100).max(100).unitNumber(0.1),
                },
                {
                    title: 'SH(Kof): Delta P',
                    code: 'active_power_frequency_sh',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(-100).max(100).unitNumber(0.1),
                },
                {
                    title: 'X1: Frequency(fPmax)',
                    code: 'active_power_frequency_x1',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001),
                },
                {
                    title: 'X2: Frequency(fStop_CH)',
                    code: 'active_power_frequency_x2',
                    type: 'number',
                    min: '45.000',
                    max: '60.500',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001),
                },
                {
                    title: 'X3: Frequency(fLLCO)',
                    code: 'active_power_frequency_x3',
                    type: 'number',
                    min: '45.000',
                    max: '60.900',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001),
                },
                {
                    title: 'X4: Frequency(fULCO)',
                    code: 'active_power_frequency_x4',
                    type: 'number',
                    min: '50.000',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(65).unitNumber(0.001),
                },
                {
                    title: 'X5: Frequency(fTransition)',
                    code: 'active_power_frequency_x5',
                    type: 'number',
                    min: '50.000',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(65).unitNumber(0.001),
                },
                {
                    title: 'X6: Frequency(fPmin)',
                    code: 'active_power_frequency_x6',
                    type: 'number',
                    min: '50.000',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(65).unitNumber(0.001),
                },
                {
                    title: 'UF Hysteresis Frequency',
                    code: 'active_power_frequency_uf_hysteresis_frequency',
                    type: 'number',
                    min: '45.000',
                    max: '60.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(45).max(60).unitNumber(0.001),
                },
                {
                    title: 'UF Hysteresis Time',
                    code: 'active_power_frequency_uf_hysteresis_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
                {
                    title: 'OF Hysteresis Frequency',
                    code: 'active_power_frequency_of_hysteresis_frequency',
                    type: 'number',
                    min: '50.000',
                    max: '65.000',
                    unit: 'Hz',
                    schema: () => numberValidator().min(50).max(65).unitNumber(0.001),
                },
                {
                    title: 'OF Hysteresis Time',
                    code: 'active_power_frequency_of_hysteresis_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
                {
                    title: 'P(freq) Response Time',
                    code: 'active_power_frequency_response_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
                {
                    title: 'P(over_freq) Delay Time',
                    code: 'active_power_over_frequency_delay_time',
                    type: 'number',
                    min: '0',
                    max: '2000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(2000),
                },
                {
                    title: 'P(under_freq) Delay Time',
                    code: 'active_power_under_frequency_delay_time',
                    type: 'number',
                    min: '0',
                    max: '2000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(2000),
                },
            ],
        },
        {
            groupTitle: 'Active Power Voltage P(V)',
            children: [
                {
                    title: 'Voltage',
                    code: 'active_power_voltage_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'X1: Voltage(VW1_CH)',
                    code: 'active_power_voltage_x1',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1),
                },
                {
                    title: 'Y1: Power',
                    code: 'active_power_voltage_y1',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(-100).max(100).unitNumber(0.1),
                },
                {
                    title: 'X2: Voltage(VW2_CH)',
                    code: 'active_power_voltage_x2',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1),
                },
                {
                    title: 'Y2: Power',
                    code: 'active_power_voltage_y2',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(-100).max(100).unitNumber(0.1),
                },
                {
                    title: 'X3: Voltage(VW1)',
                    code: 'active_power_voltage_x3',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1),
                },
                {
                    title: 'Y3: Power',
                    code: 'active_power_voltage_y3',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(-100).max(100).unitNumber(0.1),
                },
                {
                    title: 'X4: Voltage(VW2)',
                    code: 'active_power_voltage_x4',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1),
                },
                {
                    title: 'Y4: Power',
                    code: 'active_power_voltage_y4',
                    type: 'number',
                    min: '-100.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(-100).max(100).unitNumber(0.1),
                },
                {
                    title: 'P(V) Response Time',
                    code: 'active_power_voltage_response_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
            ],
        },
        {
            groupTitle: 'Power Factor SetPoint',
            children: [
                {
                    title: 'SetPoint',
                    code: 'reactive_power_cospi_set_point_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'SetPoint Excited',
                    code: 'reactive_power_cospi_set_point_excited_select',
                    type: 'radio',
                    options: [
                        { text: 'Under', value: 0 },
                        { text: 'Over', value: 1 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'SetPoint Value',
                    code: 'reactive_power_cospi_set_point_value',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => numberValidator().min(0.0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Cospi(Setpoint) Response Time',
                    code: 'reactive_power_cospi_set_point_response_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
            ],
        },
        {
            groupTitle: 'Reactive Power Cospi(P)',
            children: [
                {
                    title: 'Cospi(P)',
                    code: 'reactive_power_cospi_p_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Cospi(P) Excited',
                    code: 'reactive_power_cospi_p_excited_select',
                    type: 'radio',
                    options: [
                        { text: 'Under', value: 0 },
                        { text: 'Over', value: 1 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'X1: Power',
                    code: 'reactive_power_cospi_p_x1',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y1: Power Factor',
                    code: 'reactive_power_cospi_p_y1',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'X2: Power',
                    code: 'reactive_power_cospi_p_x2',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y2: Power Factor',
                    code: 'reactive_power_cospi_p_y2',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'X3: Power',
                    code: 'reactive_power_cospi_p_x3',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y3: Power Factor',
                    code: 'reactive_power_cospi_p_y3',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Cospi(P) Response Time',
                    code: 'reactive_power_cospi_p_response_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
            ],
        },
        {
            groupTitle: 'Reactive Power Q(P)',
            children: [
                {
                    title: 'Q(P)',
                    code: 'reactive_power_q_p_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'X1(Gen) : Power(P1)',
                    code: 'reactive_power_q_p_gen_x1',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y1(Gen) : Reactive Power(Q1)',
                    code: 'reactive_power_q_p_gen_y1',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: 'X2(Gen) : Power(P2)',
                    code: 'reactive_power_q_p_gen_x2',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y2(Gen) : Reactive Power(Q2)',
                    code: 'reactive_power_q_p_gen_y2',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: 'X3(Gen) : Power(P3)',
                    code: 'reactive_power_q_p_gen_x3',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: 'Y3(Gen) : Reactive Power(Q3)',
                    code: 'reactive_power_q_p_gen_y3',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: "X1(Load) : Power(P'1)",
                    code: 'reactive_power_q_p_load_x1',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: "Y1(Load) : Reactive Power(Q'1)",
                    code: 'reactive_power_q_p_load_y1',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: "X2(Load) : Power(P'2)",
                    code: 'reactive_power_q_p_load_x2',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: "Y2(Load) : Reactive Power(Q'2)",
                    code: 'reactive_power_q_p_load_y2',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: "X3(Load) : Power(P'3)",
                    code: 'reactive_power_q_p_load_x3',
                    type: 'number',
                    min: '0.0',
                    max: '100.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.1),
                },
                {
                    title: "Y3(Load) : Reactive Power(Q'3)",
                    code: 'reactive_power_q_p_load_y3',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: 'Q(P) Response Time',
                    code: 'reactive_power_q_p_response_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
            ],
        },
        {
            groupTitle: 'Reactive Power Q SetPoint',
            children: [
                {
                    title: 'Q(SetPoint)',
                    code: 'reactive_power_q_set_point_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Q(SetPoint) Excited',
                    code: 'reactive_power_q_set_point_excited_select',
                    type: 'radio',
                    options: [
                        { text: 'Under', value: 0 },
                        { text: 'Over', value: 1 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Q(SetPoint) Value(Q/S):',
                    code: 'reactive_power_q_set_point_value',
                    type: 'number',
                    min: '0.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(60).unitNumber(0.01),
                },
                {
                    title: 'Q(Setpoint) Response Time',
                    code: 'reactive_power_q_set_point_response_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
            ],
        },
        {
            groupTitle: 'Reactive Power Q(U)',
            children: [
                {
                    title: 'Q(U)',
                    code: 'reactive_power_q_u_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Fixed Vref Select',
                    code: 'reactive_power_q_u_fixed_vref_select',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Q(U) Vref',
                    code: 'reactive_power_q_u_vref',
                    type: 'number',
                    min: '0.0',
                    max: '300.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(300).unitNumber(0.1),
                },
                {
                    title: 'Auto Vref Avg Time',
                    code: 'reactive_power_auto_vref_avg_time',
                    type: 'number',
                    min: '0.0',
                    max: '5000.0',
                    unit: 's',
                    schema: () => numberValidator().min(0).max(5000).unitNumber(0.1),
                },
                {
                    title: 'X1: Voltage',
                    code: 'reactive_power_q_u_x1',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1),
                },
                {
                    title: 'Y1: Reactive',
                    code: 'reactive_power_q_u_y1',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: 'X2: Voltage',
                    code: 'reactive_power_q_u_x2',
                    type: 'number',
                    min: '0.0',
                    max: '240.0',
                    unit: 'V',
                    schema: () => numberValidator().min(0).max(240).unitNumber(0.1),
                },
                {
                    title: 'Y2: Reactive',
                    code: 'reactive_power_q_u_y2',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: 'X3: Voltage',
                    code: 'reactive_power_q_u_x3',
                    type: 'number',
                    min: '200.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(200).max(350).unitNumber(0.1),
                },
                {
                    title: 'Y3: Reactive',
                    code: 'reactive_power_q_u_y3',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: 'X4: Voltage',
                    code: 'reactive_power_q_u_x4',
                    type: 'number',
                    min: '200.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(200).max(350).unitNumber(0.1),
                },
                {
                    title: 'Y4: Reactive',
                    code: 'reactive_power_q_u_y4',
                    type: 'number',
                    min: '-60.00',
                    max: '60.00',
                    unit: '%',
                    schema: () => numberValidator().min(-60).max(60).unitNumber(0.01),
                },
                {
                    title: 'Q(U) Response Time',
                    code: 'reactive_power_q_u_response_time',
                    type: 'number',
                    min: '0',
                    max: '60000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(60000),
                },
            ],
        },
        {
            groupTitle: 'Dynamic Reactive Current Support',
            children: [
                {
                    title: 'R(U)',
                    code: 'dynamic_reactive_current_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'ArGraMod',
                    code: 'dynamic_reactive_current_ar_gra_mod',
                    type: 'selector',
                    options: [
                        { text: 'Undefined', value: 0 },
                        { text: 'Basic', value: 1 },
                        { text: 'Alternatice', value: 2 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'X1: DbVMin',
                    code: 'dynamic_reactive_current_db_v_min',
                    type: 'number',
                    min: '0.0',
                    max: '60.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(60).unitNumber(0.1),
                },
                {
                    title: 'X2: DbVMax',
                    code: 'dynamic_reactive_current_db_v_max',
                    type: 'number',
                    min: '0.0',
                    max: '60.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(60).unitNumber(0.1),
                },
                {
                    title: 'ArGraSag(Capacitive)',
                    code: 'dynamic_reactive_current_ar_gra_sag',
                    type: 'number',
                    min: '0.00',
                    max: '60.00',
                    unit: 'A',
                    schema: () => numberValidator().min(0).max(60).unitNumber(0.01),
                },
                {
                    title: 'ArGraSwell(Inductive)',
                    code: 'dynamic_reactive_current_ar_gra_swell',
                    type: 'number',
                    min: '0.00',
                    max: '60.00',
                    unit: 'A',
                    schema: () => numberValidator().min(0).max(60).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: 'Voltage Rise Suppression',
            children: [
                {
                    title: 'Voltage Rise Suppression',
                    code: 'voltage_rise_suppression_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Vref(Target Voltage)',
                    code: 'voltage_rise_suppression_vref',
                    type: 'number',
                    min: '100.0',
                    max: '350.0',
                    unit: 'V',
                    schema: () => numberValidator().min(100).max(350).unitNumber(0.1),
                },
                {
                    title: 'Delay Time',
                    code: 'voltage_rise_suppression_delay_time',
                    type: 'number',
                    min: '0.0',
                    max: '5000.0',
                    unit: 's',
                    schema: () => numberValidator().min(0).max(5000).unitNumber(0.1),
                },
                {
                    title: 'Min.PF',
                    code: 'voltage_rise_suppression_min_pf',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'PF Response Time',
                    code: 'voltage_rise_suppression_pf_response_time',
                    type: 'number',
                    min: '0.0',
                    max: '5000.0',
                    unit: 's',
                    schema: () => numberValidator().min(0).max(5000).unitNumber(0.1),
                },
                {
                    title: 'Min. ActivePower',
                    code: 'voltage_rise_suppression_min_active_power',
                    type: 'number',
                    min: '0.00',
                    max: '100.00',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.01),
                },
                {
                    title: 'Active Power Response Time',
                    code: 'voltage_rise_suppression_active_power_response_time',
                    type: 'number',
                    min: '0.0',
                    max: '5000.0',
                    unit: 's',
                    schema: () => numberValidator().min(0).max(5000).unitNumber(0.1),
                },
                {
                    title: 'Release Time',
                    code: 'voltage_rise_suppression_release_time',
                    type: 'number',
                    min: '0.0',
                    max: '5000.0',
                    unit: 's',
                    schema: () => numberValidator().min(0).max(5000).unitNumber(0.1),
                },
            ],
        },
        {
            groupTitle: 'DC_Injection Control',
            children: [
                {
                    title: 'Detection Enable',
                    code: 'dc_injection_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Fault_Level1',
                    code: 'dc_injection_detection_level1',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    unit: 'A',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Fault_Time1',
                    code: 'dc_injection_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Fault_Level2',
                    code: 'dc_injection_detection_level2',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    unit: 'A',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Fault_Time2',
                    code: 'dc_injection_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Control Enable',
                    code: 'dc_injection_control_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Control_Level',
                    code: 'dc_injection_control_level',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    unit: 'A',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: 'RCMU Control',
            children: [
                { title: 'Sudden', type: 'header', code: 'rcmu_sudden_flag' },
                {
                    title: 'Enable',
                    code: 'rcmu_sudden_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Level1',
                    code: 'rcmu_sudden_detection_level1',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
                {
                    title: 'Time1',
                    code: 'rcmu_sudden_detection_time1',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
                {
                    title: 'Level2',
                    code: 'rcmu_sudden_detection_level2',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
                {
                    title: 'Time2',
                    code: 'rcmu_sudden_detection_time2',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
                {
                    title: 'Level3',
                    code: 'rcmu_sudden_detection_level3',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
                {
                    title: 'Time3',
                    code: 'rcmu_sudden_detection_time3',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
                { title: 'Continuous', type: 'header', code: 'rcmu_continuous_flag' },
                {
                    title: 'Enable',
                    code: 'rcmu_continuous_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Level',
                    code: 'rcmu_continuous_detection_level',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'mA',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
                {
                    title: 'Time',
                    code: 'rcmu_continuous_detection_time',
                    type: 'number',
                    min: '0',
                    max: '500',
                    unit: 'ms',
                    schema: () => numberValidator().integer().min(0).max(500),
                },
            ],
        },
        {
            groupTitle: 'PV_Insulation Control',
            children: [
                {
                    title: 'Enable',
                    code: 'pv_insulation_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Fault_Level',
                    code: 'pv_insulation_fault_level',
                    type: 'number',
                    min: '0.0',
                    max: '1000.0',
                    unit: 'KOhm',
                    schema: () => numberValidator().min(0).max(1000).unitNumber(0.1),
                },
                {
                    title: 'Check_Cnt',
                    code: 'pv_insulation_check_cnt',
                    type: 'number',
                    min: '1',
                    max: '100',
                    unit: 'Cnt',
                    schema: () => numberValidator().integer().min(1).max(100),
                },
                {
                    title: 'Retry Time',
                    code: 'pv_insulation_time',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: 'min',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
            ],
        },
        {
            groupTitle: 'Anti_Islanding Control',
            children: [
                {
                    title: 'Active Method Detection',
                    code: 'anti_islanding_active_method_detection',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Passvie Method Detection ',
                    code: 'anti_islanding_passive_method_detection',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Freq Feedback Function',
                    code: 'anti_islanding_freq_feedback_function',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Step Injection Function',
                    code: 'anti_islanding_step_injection_function',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Reactive Power Suppression Function',
                    code: 'anti_islanding_reactive_power_suppression',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Gain1',
                    code: 'anti_islanding_gain1',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Gain2',
                    code: 'anti_islanding_gain2',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Freq. Feedback - Qmax',
                    code: 'anti_islanding_freq_feedback_qmax',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
                {
                    title: 'Step Injection - Qmax',
                    code: 'anti_islanding_step_injection_qmax',
                    type: 'number',
                    min: '0.00',
                    max: '1.00',
                    schema: () => numberValidator().min(0).max(1).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: 'Gradient Control',
            children: [
                {
                    title: 'Enable',
                    code: 'gradient_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Energy Source Change Enable',
                    code: 'gradient_energy_source_change',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Unit Reference Select ',
                    code: 'gradient_unit_reference_select',
                    type: 'radio',
                    options: [
                        { text: 'Irated', value: 0 },
                        { text: 'Prated', value: 1 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Active Power Gradient',
                    code: 'gradient_active_power_gradient',
                    type: 'number',
                    min: '0.00',
                    max: '100.00',
                    unit: '%W/s',
                    schema: () => numberValidator().min(0).max(100).unitNumber(0.01),
                },
            ],
        },
        {
            groupTitle: 'Derating Control',
            children: [
                {
                    title: 'Enable',
                    code: 'derating_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Start_Temp',
                    code: 'derating_start_temp',
                    type: 'number',
                    min: '0.0',
                    max: '150.0',
                    unit: '℃',
                    schema: () => numberValidator().min(0).max(150).unitNumber(0.1),
                },
                {
                    title: 'Value',
                    code: 'derating_value',
                    type: 'number',
                    min: '0.0',
                    max: '60.0',
                    unit: '%',
                    schema: () => numberValidator().min(0).max(60).unitNumber(0.1),
                },
            ],
        },
        {
            groupTitle: 'Re-connection Time',
            children: [
                {
                    title: 'Time',
                    code: 'inverter_reconnection_time',
                    type: 'number',
                    min: '0',
                    max: '65535',
                    unit: 'sec',
                    schema: () => numberValidator().integer().min(0).max(65535),
                },
            ],
        },
        {
            groupTitle: 'Feed in Relay',
            children: [
                {
                    title: 'Enable',
                    code: 'feedin_relay_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Relay 1 Attach Level',
                    code: 'feedin_relay_attach_level_relay1',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Relay 1 Detach Level',
                    code: 'feedin_relay_detach_level_relay1',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Relay 2 Attach Level',
                    code: 'feedin_relay_attach_level_relay2',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Relay 2 Detach Level',
                    code: 'feedin_relay_detach_level_relay2',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Relay 3 Attach Level',
                    code: 'feedin_relay_attach_level_relay3',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Relay 3 Detach Level',
                    code: 'feedin_relay_detach_level_relay3',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Relay 4 Attach Level',
                    code: 'feedin_relay_attach_level_relay4',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
                {
                    title: 'Relay 4 Detach Level',
                    code: 'feedin_relay_detach_level_relay4',
                    type: 'number',
                    min: '0',
                    max: '10000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(0).max(10000),
                },
            ],
        },
        {
            groupTitle: 'Limit Control',
            children: [
                {
                    title: 'Export Hard Limit Flag',
                    code: 'limit_control_export_hard_limit_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Export Hard Limit Level',
                    code: 'limit_control_export_hard_limit_level',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: '%',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
                {
                    title: 'Export Hard Limit Time',
                    code: 'limit_control_export_hard_limit_time',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: 'Sec',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
                {
                    title: 'Export Soft Limit Flag',
                    code: 'limit_control_export_soft_limit_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Export Soft Limit Level',
                    code: 'limit_control_export_soft_limit_level',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: '%',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
                {
                    title: 'Export Soft Limit Time',
                    code: 'limit_control_export_soft_limit_time',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: 'Sec',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
                {
                    title: 'Generation Hard Limit Flag',
                    code: 'limit_control_generation_hard_limit_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Generation Hard Limit Level',
                    code: 'limit_control_generation_hard_limit_level',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: '%',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
                {
                    title: 'Generation Hard Limit Time',
                    code: 'limit_control_generation_hard_limit_time',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: 'Sec',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
                {
                    title: 'Generation Soft Limit Flag',
                    code: 'limit_control_generation_soft_limit_flag',
                    type: 'toggle',
                    options: [
                        { text: t('common.enable'), value: 1 },
                        { text: t('common.disable'), value: 0 },
                    ],
                    schema: () => numberValidator(),
                },
                {
                    title: 'Generation Soft Limit Level',
                    code: 'limit_control_generation_soft_limit_level',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: '%',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
                {
                    title: 'Generation Soft Limit Time',
                    code: 'limit_control_generation_soft_limit_time',
                    type: 'number',
                    min: '0',
                    max: '100',
                    unit: 'Sec',
                    schema: () => numberValidator().integer().min(0).max(100),
                },
            ],
        },
        {
            groupTitle: 'CLS',
            children: [
                {
                    title: 'Export Limit',
                    code: 'cls_export_limit',
                    type: 'number',
                    min: '5000',
                    max: '23000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(5000).max(23000),
                },
                {
                    title: 'Import Limit',
                    code: 'cls_import_limit',
                    type: 'number',
                    min: '5000',
                    max: '23000',
                    unit: 'W',
                    schema: () => numberValidator().integer().min(5000).max(23000),
                },
            ],
        },
    ];
};
